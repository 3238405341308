import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, InputForm, Spinner } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import Table from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import React, { Fragment, useMemo } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  RiArrowLeftLine,
  RiDeleteBin5Line,
  RiInformationLine,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { getAssignPelaksana } from "services/fdb/desk-analyst";
import {
  getAssignOffersDetail,
  putAssignOffersDetail,
} from "services/fdb/offers";
import Select from "react-select";
import AtomSelect, { customStyles } from "components/atoms/Select";
import { BiPlus } from "react-icons/bi";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { isEmpty } from "lodash";

const AssignOffersDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: assignee } = useQuery({
    queryKey: ["GET DESK ANALYST"],
    queryFn: getAssignPelaksana,
    select: ({ data }) => {
      return data?.data?.map((x) => ({ ...x, id: x.id, name: x.name }));
    },
  });

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      bid_executors: [{ user_id: null, name: "", email: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "bid_executors",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["application-desk-analyst-detail"],
    queryFn: async () => {
      const res = await getAssignOffersDetail(id);
      return res.data.data;
    },
    onSuccess: (data) => {
      if (data?.bid_executors?.length > 0) {
        const temp = data?.bid_executors?.map((x) => ({
          user_id: {
            value: x.user_id,
            label: x.name
          },
          name: x.name,
          email: x.email,
        }));
        methods.reset({
          bid_executors: temp,
        });
      }
    },
  });

  const bid_executorsWatch = useWatch({
    control: methods.control,
    name: "bid_executors",
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama Pelaksana",
        render: ({ item, index }) => {
          return (
            <AtomSelect
              controllerName={`bid_executors[${index}].user_id`}
              options={assignee?.map((data) => ({
                ...data,
                label: data.name,
                value: data.idUser,
              }))}
              menuPosition="fixed"
              customOnChange={(e) => {
                methods.setValue(`bid_executors[${index}].name`, e.name);
                methods.setValue(
                  `bid_executors[${index}].email`,
                  e.emailPegawai
                );
              }}
            />
          );
        },
      },
      {
        key: "email",
        title: "Email Pelaksana",
        render: ({ item, index }) => {
          return (
            <InputForm
              className="!max-w-full"
              controllerName={`bid_executors[${index}].email`}
              disabled
            />
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item, index }) =>
          index > 0 ? (
            <Button
              key={item.id}
              variant="icon"
              label={<RiDeleteBin5Line className="text-lg" />}
              onClick={() => {
                remove(item.id);
              }}
            />
          ) : null,
      },
    ];
  }, [methods, remove, assignee]);

  const onFormSubmit = () => {
    const temp = methods.getValues();
    const payload = {
      ...temp,
      bid_executors: temp.bid_executors.map((x) => ({
        ...x,
        user_id: x?.user_id?.value
      }))
    };
    postAssignPelaksana.mutate(payload);
  };

  const postAssignPelaksana = useMutation({
    mutationKey: ["post-assign-pelaksana"],
    mutationFn: (payload) => {
      const res = putAssignOffersDetail(id, payload);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
      navigate("/assign-offers");
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  return (
    <Fragment>
      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="space-y-6">
          <CardForm label="Profile Kelompok">
            <div className="flex justify-between">
              <div>
                <p className="font-semibold">Nama Kelompok</p>
                <p>{data.group_profile.name}</p>
              </div>
              <div>
                <p className="font-semibold">Nama Ketua Kelompok</p>
                <p>{data.group_profile.leader_name}</p>
              </div>
              <div>
                <p className="font-semibold">NIK Ketua</p>
                <p>{data.group_profile.leader_identity}</p>
              </div>
              <div>
                <p className="font-semibold">No Telepon Ketua</p>
                <p>{data.group_profile.leader_phone}</p>
              </div>
            </div>
          </CardForm>
          <CardForm label="Assign Pelaksana">
            <div className="space-y-6">
              <FormProvider {...methods}>
                <div className="space-y-6">
                  <Table
                    headers={headers}
                    items={fields}
                    isLoading={isLoading}
                  />
                  <div className="flex justify-end">
                    <Button
                      theme="primary"
                      label={
                        <div className="flex gap-3 items-center">
                          <BiPlus className="text-white" size={22} />
                          Tambah Pelaksana
                        </div>
                      }
                      className="!p-3.5"
                      onClick={() => {
                        append({
                          user_id: "",
                          name: "",
                          email: "",
                        });
                      }}
                    />
                  </div>
                </div>
              </FormProvider>
            </div>
          </CardForm>
          <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
            <Button
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2">
                  <RiArrowLeftLine />
                  Batal
                </div>
              }
              onClick={() => navigate(-1)}
            />
            <ModalConfirm
              hasExecutors={bid_executorsWatch?.every(
                (x) => !isEmpty(x.user_id)
              )}
              onSubmit={() => methods.handleSubmit(onFormSubmit)()}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const ModalConfirm = ({ onSubmit, hasExecutors }) => {
  return (
    <Modal trigger={<Button label="Submit" theme="primary" />}>
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
            <RiInformationLine className="text-base" size={24} />
          </div>
        }
      />
      {hasExecutors ? (
        <ModalBody className="space-y-4">
          <p className="text-lg font-semibold">
            Anda yakin akan mengirimkan Assignment Ke PIC Terpilih?
          </p>
          <small>Setelah data Terkirim Anda masih Bisa Mengubah PIC</small>
          <div className="grid grid-cols-2 gap-4">
            <ModalClose>
              <Button
                label="Tidak"
                theme="secondary"
                block
                className="w-full"
              />
            </ModalClose>
            <Button
              label={"Ya"}
              theme="primary"
              block
              className="w-full"
              onClick={onSubmit}
            />
          </div>
        </ModalBody>
      ) : (
        <ModalBody className="space-y-4">
          <p className="text-lg font-semibold text-center">
            Anda wajib memasukkan minimal satu pelaksana
          </p>
          <ModalClose>
            <Button label="Tutup" theme="secondary" block className="w-full" />
          </ModalClose>
        </ModalBody>
      )}
    </Modal>
  );
};

export default AssignOffersDetail;
