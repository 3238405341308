import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { RiDownload2Line } from "react-icons/ri";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import BreadCrumbs from "components/molecules/Breadcrumbs";
import { BottomFormAction, InputForm, InputFormUpload } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import Button from "components/atoms/Button";
import CardForm from "components/molecules/CardForm";

import { createOffer } from "services/institutions/offers";
import { fileUpload } from "services/fileService";
import withFileService from "hocs/with-file-service";

import SelectDirectorMinutes from "./SelectDirectorMinutes";
import validationSchema from "./validation-schema";
import Reporting from "./Reporting";
import Provision from "./Provision";
import RichEditorForm from "components/molecules/RichEditorForm";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

const defaultValues = {
  cooperation_terms: [
    {
      value: null,
    },
  ],
  report: [
    {
      value: null,
    },
  ],
  agency_committee_minute_id: null,
};

const InstitutionCreateOffers = withFileService((props) => {
  const { getFileFromService } = props;
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema()),
    defaultValues,
  });
  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const mutation = useMutation({
    mutationFn: (data) => {
      return createOffer(data);
    },
  });

  const onFormSubmit = useCallback((payload) => {
    const dataToSend = {
      ...payload,
      agency_committee_minute_id: payload?.agency_committee_minute_id?.value || null,
      start_date: dayjs(payload.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(payload.end_date).format("YYYY-MM-DD"),
    };
    delete dataToSend.fileTemp;

    mutation.mutate(dataToSend, {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
        navigate("/institutions/offers");
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });
  }, []);

  const onDraft = useCallback(() => { }, []);

  const onDownloadTemplate = () => {
    getFileFromService(process.env.REACT_APP_OFFER_TEMPLATE_ID, {
      onSuccess: (data) => {
        const downloadLink = window.document.createElement("a");
        downloadLink.href = data.url;
        downloadLink.download = "Template_Surat_Penawaran.docx";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const fileMutation = useMutation(async (file) => {
    try {
      const validFileTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ];

      if (validFileTypes.includes(file.file.type)) {
        const response = await fileUpload(file);
        methods.register("file");
        methods.setValue("file", response.data.data.id);
      } else {
        methods.setError("fileTemp", {
          type: "custom",
          message: "File harus berupa dokumen Word atau PDF",
        });
      }
    } catch (error) {
      throw error;
    }
  });

  return (
    <div className="space-y-5 mb-40">
      <FormProvider {...methods}>
        <BreadCrumbs
          routes={[
            {
              label: "Daftar Penawaran - Lembaga Penyalur",
              path: "/institutions/offers",
            },
            {
              label: "Tambah Penawaran Kerjasama",
              path: "/institutions/offers/create",
            },
          ]}
        />
        <CardForm label="Informasi Penawaran">
          <div className="flex justify-between gap-5">
            <InputForm
              controllerName={"number"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Nomor Surat"}
              placeholder={"Nomor Surat"}
              required
            />

            <InputForm
              controllerName={"nature"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Sifat"}
              placeholder={"Sifat"}
              required
            />
          </div>
          <div className="flex justify-between gap-5">
            <InputForm
              controllerName={"some"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Hal"}
              placeholder={"Hal"}
              required
            />
          </div>
          <div className="flex justify-between gap-5">
            <div className="form-control w-full flex flex-col justify-end">
              <label className="label flex gap-1 font-semibold text-[14px] mb-2">
                <span className={`label-text`}>Berdasarkan BA Komite Direksi</span>
                <span className="text-[#F04438]">*</span>
              </label>
              <SelectDirectorMinutes
                name="agency_committee_minute_id"
                customOnChange={(values) => {
                  methods.setValue(
                    "agency_name",
                    values?.agency_name
                  );
                }}
              />
            </div>
            <InputForm
              controllerName={"agency_name"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Ditujukan Untuk Lembaga"}
              placeholder={"Ditujukan Untuk Lembaga"}
              required
              disabled
            />
          </div>
          <div className="flex justify-between gap-5">
            <AtomDatePicker
              controllerName={"start_date"}
              label="Tanggal Pelaksanaan Penawaran"
              placeholder="Tanggal Pelaksanaan Penawaran"
              datepickerOptions={{
                dateFormat: "dd/MM/yyyy",
              }}
              showErrorLabel
              required
            />
            <AtomDatePicker
              controllerName={"end_date"}
              label="Tanggal Penawaran Berakhir"
              placeholder="Tanggal Penawaran Berakhir"
              datepickerOptions={{
                dateFormat: "dd/MM/yyyy",
              }}
              showErrorLabel
              required
            />
          </div>
        </CardForm>
        <CardForm
          label="Surat Penawaran"
          rightSlot={() => {
            return (
              <Button
                className="p-4 border rounded-xl bg-white shadow-md"
                label={
                  <div className="flex items-center gap-2">
                    <RiDownload2Line size={22} />
                    Template Surat Penawaran
                  </div>
                }
                onClick={onDownloadTemplate}
              />
            );
          }}
        >
          <InputFormUpload
            controllerName={`fileTemp`}
            fileType="PDF, Docx"
            label={"Upload Surat Penawaran"}
            uploadFile={fileMutation}
            maxSize={10 * 1024 * 1024}
          />
        </CardForm>
        <CardForm label="Lampiran Penawaran Kerjasama">
          <RichEditorForm
            className="quill-6"
            name={"fdb_syara_agreement"}
            label="Syarat Penandatanganan Perjanjian FDB"
            required={true}
          />
          <Provision />
          <RichEditorForm
            className="quill-6"
            name={"disbursement_terms"}
            label="Ketentuan Pencairan dan Pengembalian FDB"
            required={true}
          />
          <Reporting />
          <RichEditorForm
            className="quill-6"
            name={"monitoring_terms"}
            label="Ketentuan Monitoring dan Evaluasi"
            required={true}
          />
          <RichEditorForm
            className="quill-6"
            name={"tax_terms"}
            label="Ketentuan Denda dan Wanprestasi"
            required={true}
          />
          <RichEditorForm
            className="quill-6"
            name={"end_agreement_terms"}
            label="Ketentuan Berakhirnya Perjanjian Kerjasama Penyaluran FDB"
            required={true}
          />
          <RichEditorForm
            className="quill-6"
            name={"settlement_terms"}
            label="Ketentuan Penyelamatan dan Penyelesaian FDB"
            required={true}
          />
        </CardForm>
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          hideDraft={true}
          lastStep={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          submitButtonProps={{ type: "submit" }}
        />
      </FormProvider>
    </div>
  );
});

export default InstitutionCreateOffers;
