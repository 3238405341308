import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";

import { BottomFormAction, InputFormUpload } from "components";
import { TabsContent } from "components/atoms/Tabs";
import CardForm from "components/molecules/CardForm";
import AccordionButton from "components/atoms/AccordionButton";
import Table, { TableWrapper } from "components/atoms/Table";
import { formatCurrency } from "helpers";
import AtomDatePicker from "components/atoms/Datepicker";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";


const validateionScehem = yup.object({
  interview_date: yup.string().required().label('Tanggal interview'),
  file_temp: yup
    .mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                label: "Dokumen Berita Acara Desk Analis",
              }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Dokumen Berita Acara Desk Analis",
              }),
            });
          }
          return true;
        }
        // otherwise, return false to render the field required
        return false;
      }
    )
    .label("Dokumen Hasil Wawancara Lemabaga Penyalur"),
})

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const headers = [
  {
    key: "index",
    title: "No",
    render: ({ index }) => index + 1,
  },
  {
    key: "question",
    title: "Pertanyaan",
  },
  {
    key: "answer",
    title: "Jawaban",
  },
  {
    key: "radioValue",
    title: "Sesuai",
    render: ({ item }) => (
      <div className="radio-buttons">
        <input
          type="radio"
          id={`radioOption1-${item.id}`}
          name={`radioOptions-${item.id}`}
          defaultChecked={item.verification === true}
          // value="option1"
          onChange={(e) => {
            if (e.target.checked) {
              item.verification = true;
            }
          }}
        />
      </div>
    ),
  },
  {
    key: "radioValue",
    title: "Tidak Sesuai",
    render: ({ item, data }) => (
      <div className="radio-buttons">
        <input
          type="radio"
          id={`radioOption2-${item.id}`}
          name={`radioOptions-${item.id}`}
          // value="option2"
          defaultChecked={item.verification === false}
          onChange={(e) => {
            if (e.target.checked) {
              item.verification = false;
            }
          }}
        />
      </div>
    ),
  },
];

const Condition = ({ data }) => {
  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} />
    </TableWrapper>
  );
};

const Performance = ({ data }) => {
  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} />
    </TableWrapper>
  );
};

const Experience = ({ data }) => {
  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} />
    </TableWrapper>
  );
};

const Criteria = ({ data }) => {
  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} />
    </TableWrapper>
  );
};

const ITSystem = ({ data }) => {
  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} />
    </TableWrapper>
  );
};

const Assurance = ({ data }) => {
  const assuranceTypes = [
    {
      type: "Ruko",
      value: 1000000,
    },
    {
      type: "Tanah",
      value: 1000000,
    },
    {
      type: "Bangunan / Rumah",
      value: 1000000,
    },
  ];

  const collaterals = [
    {
      name: "Tagihan Lancar",
      value: 1000000,
    },
    {
      name: "Cash Collateral",
      value: 1000000,
    },
    {
      name: "....",
      value: 1000000,
    },
  ];
  return (
    <div className="space-y-4">
      <div>
        <div className="bg-[#E1F1D6] my-5 p-3 w-full">
          <p className="font-bold text-[#01A24A]">Jenis Jaminan</p>
        </div>
        <table className="min-w-full table-fixed">
          <thead>
            <tr>
              <th scope="col" className="p-2 font-semibold text-left">
                Jaminan Aset Tetap
              </th>
              <th scope="col" className="p-2 font-semibold text-left">
                Perkiraan Nilai
              </th>
            </tr>
          </thead>
          <tbody>
            {assuranceTypes.map((x, key) => (
              <tr key={key}>
                <td className="p-2">{x.type}</td>
                <td className="p-2">{formatCurrency(x.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <div className="bg-[#E1F1D6] my-5 p-3 w-full">
          <p className="font-bold text-[#01A24A]">Jaminan Aset Bergerak</p>
        </div>
        <table className="min-w-full table-fixed">
          <tbody>
            {collaterals.map((x, key) => (
              <tr key={key}>
                <td className="p-2">{x.name}</td>
                <td className="p-2">{formatCurrency(x.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-[#E1F1D6] my-5 p-3 w-full flex space-x-4">
        <p className="font-bold text-[#01A24A] flex-1">TOTAL</p>
        <p className="font-bold text-[#01A24A] flex-1">{formatCurrency(1000000)}</p>
      </div>
    </div>
  );
};

const Interview = ({ onSubmit, onBack, initialValues, data }) => {
  const [accordionState, setAccordionState] = useState({
    condition: true,
    performance: true,
    experience: true,
    criteria: true,
    it_system: true,
    assurance: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const methods = useForm({
    resolver: yupResolver(validateionScehem)
  });
  const handleBack = () => {
    const values = methods.getValues();
    onBack(values);
  };

  const handleSubmit = () => {
    const payload = methods.getValues();
    onSubmit(payload);
  };

  const handleDraftButton = () => {
    console.log("draft");
  };

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("interview_file_temp");
      methods.setValue("interview_file_temp", response.data.data.id);
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    if(data) {
      const promises = [];
      const { interview_file_temp, interview_date } = data ?? {}

      if(interview_file_temp) {
        promises.push(
          getFileFromServiceCB(interview_file_temp).then((data) => {
            return {
              formId: "file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }

      if(promises?.length) {
        Promise.all(promises).then((data) => {
          methods.reset({
            file_temp: data[0],
            interview_date: new Date(interview_date),
            // interview_date: data.recomendation.interview_date,
          })
        })
      }

      // methods.reset({
      //   interview_date,
      //   // interview_date: data.recomendation.interview_date,
      // })
    }
  }, [data, methods])

  return (
    <TabsContent value="interview" className="mb-40">
      <FormProvider {...methods}>
        {/* <CardForm label="Self Assesment Lembaga Penyalur">
          <AnimatePresence>
            <AccordionButton
              key={"accordion-address-domicile-btn"}
              label={"Kondisi Umum"}
              isOpen={accordionState?.condition}
              onClick={toggleAccordion("condition")}
            />
            {accordionState?.condition && (
              <motion.div
                key={"accordion-address-domicile-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <Condition data={initialValues?.questions1 ?? []} />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            <AccordionButton
              key={"accordion-address-domicile-btn"}
              label={"Kriteria Sehat & Kinerja Baik"}
              isOpen={accordionState?.performance}
              onClick={toggleAccordion("performance")}
            />
            {accordionState?.performance && (
              <motion.div
                key={"accordion-address-domicile-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <Performance data={initialValues?.questions2 ?? []} />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            <AccordionButton
              key={"accordion-address-domicile-btn"}
              label={"Pengalaman Pembiayaan / Investasi Lingkungan"}
              isOpen={accordionState?.experience}
              onClick={toggleAccordion("experience")}
            />
            {accordionState?.experience && (
              <motion.div
                key={"accordion-address-domicile-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <Experience data={initialValues?.questions3 ?? []} />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            <AccordionButton
              key={"accordion-address-domicile-btn"}
              label={"Kriteria Akses Langsung"}
              isOpen={accordionState?.criteria}
              onClick={toggleAccordion("criteria")}
            />
            {accordionState?.criteria && (
              <motion.div
                key={"accordion-address-domicile-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <Criteria data={initialValues?.questions4 ?? []} />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            <AccordionButton
              key={"accordion-address-domicile-btn"}
              label={"Kepemilikan Sistem IT"}
              isOpen={accordionState?.it_system}
              onClick={toggleAccordion("it_system")}
            />
            {accordionState?.it_system && (
              <motion.div
                key={"accordion-address-domicile-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <ITSystem data={initialValues?.questions5 ?? []} />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            <AccordionButton
              key={"accordion-address-domicile-btn"}
              label={"Jaminan"}
              isOpen={accordionState?.assurance}
              onClick={toggleAccordion("?.assurance")}
            />
            {accordionState?.assurance && (
              <motion.div
                key={"accordion-address-domicile-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <Assurance data={{}} />
              </motion.div>
            )}
          </AnimatePresence>
        </CardForm> */}

        <CardForm label="Hasil Wawancara">
          <div className="space-y-4">
            <AtomDatePicker
              controllerName={"interview_date"}
              label="Tanggal Wawancara Lembaga Penyalur"
              placeholder="dd/mm/yyyy"
              datepickerOptions={{
                dateFormat: "dd/MM/yyyy",
              }}
              showErrorLabel
              required
            />

            <InputFormUpload
              uploadFile={fileMutation}
              fileType="PDF, Docx"
              controllerName="file_temp"
              maxSize={10 * 1024 * 1024}
              className="inset-0"
              label="Upload Hasil Wawancara Lembaga Penyalur"
            />
          </div>

        </CardForm>
        <BottomFormAction
          backButtonAction={handleBack}
          submitActionButton={() => methods.handleSubmit(handleSubmit)()}
          disableDrafButton={false}
          hideSubmit={false}
          hideDraft
          submitButtonProps={{
            type: "submit",
            label: "Selanjutnya",
          }}
          drafButtonAction={handleDraftButton}
          disableButtonSubmit={false}
          // hideDraft={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </FormProvider>
    </TabsContent>
  );
};

export default Interview;
