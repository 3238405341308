/* eslint-disable array-callback-return */
/* eslint-disable no-template-curly-in-string */
import React, { useEffect } from "react";
import { TabsContent } from "components/atoms/Tabs";
import CardForm from "components/molecules/CardForm";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";
import { FormProvider, useForm } from "react-hook-form";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import AtomDatePicker from "components/atoms/Datepicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { find, isEmpty } from "lodash";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { useSearchParams } from "react-router-dom";

const validationScheme = yup.object({
  field_number: yup.string().required().label("No Berita Acara Field Analis"),
  field_date: yup
    .string()
    .required()
    .label("Tanggal Terbit Berita Acara Field Analis"),
  file_temp: yup
    .mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                label: "Dokumen Berita Acara Desk Analis",
              }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Dokumen Berita Acara Desk Analis",
              }),
            });
          }
          return true;
        }
        // otherwise, return false to render the field required
        return false;
      }
    )
    .label("Dokumen Berita Acara Field Analis LP"),
  form_law: yup.string().required().label("Bentuk Badan Hukum Lembaga"),
  is_have_legality: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  is_have_healthy: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  is_good_work: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  is_two_years_experience: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  is_have_portfolio: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  is_debtor_access: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  is_it_system: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  is_collateral: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
  sampling_result: yup.string().required().label(""),
  is_continue: yup
    .bool()
    .transform((val) => (typeof val !== "boolean" ? null : val))
    .label("Opsi ini"),
});

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const FieldResult = ({ data, onBack, onSubmit }) => {
  const [searchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(validationScheme),
  });

  const { register, setValue } = methods;

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "file_temp") {
        register("file");
        setValue("file", response.data.data.id);
      }
    } catch (error) {
      throw error;
    }
  });

  const handleBack = () => {
    const values = methods.getValues();
    onBack(values);
  };

  const handleSubmit = (payload) => {
    onSubmit(payload);
  };

  const handleDraftButton = () => {
    console.log("draft");
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const promises = [];
      const { file, field_date, ...rest } = data || { file: null };

      if (file && file !== "string") {
        promises.push(
          getFileFromServiceCB(file).then((data) => {
            return {
              formId: "file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      } else {
        promises.push(Promise.resolve(null));
      }
      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = {
            ...rest,
            field_date: field_date ? new Date(field_date) : undefined,
          };

          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });
          methods.reset(obj);
        });
      }
    }
  }, [data, methods]);

  return (
    <TabsContent value="field-result" className="mb-40">
      <FormProvider {...methods}>
        <div className="space-y-5">
          <CardForm label="Penilaian Lembaga Penyalur">
            <div className="space-y-4">
              <div className="flex gap-4">
                <InputForm
                  label="No Berita Acara Field Analis"
                  required={true}
                  controllerName={"field_number"}
                  className={"rounded-md w-full"}
                />
                <AtomDatePicker
                  controllerName={"field_date"}
                  label="Tanggal Terbit Berita Acara Field Analis"
                  placeholder="dd/mm/yyyy"
                  datepickerOptions={{
                    dateFormat: "dd/MM/yyyy",
                  }}
                  showErrorLabel
                  required
                />
              </div>
              <InputFormUpload
                uploadFile={fileMutation}
                fileType="PDF, Docx"
                controllerName="file_temp"
                maxSize={10 * 1024 * 1024}
                className="inset-0"
                label="Upload Berita Acara Field Analis LP"
              />
            </div>
          </CardForm>
          <CardForm label="Kesimpulan Hasil Kegiatan">
            <div className="flex gap-4">
              <InputFormRadio
                label={"1. Dokumen Legalitas Calon Lembaga Penyalur"}
                controllerName={"is_have_legality"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Memiliki Legalitas
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Memiliki Legalitas
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              <InputForm
                label="Bentuk Badan Hukum Lembaga"
                required={true}
                controllerName={"form_law"}
                className={"rounded-md w-full"}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"2. Sehat dan Berkinerja Baik"}
                controllerName={"is_have_healthy"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Memiliki Kriteria Sehat
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Memiliki Kriteria Sehat
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              <InputFormRadio
                label="&nbsp;"
                controllerName={"is_good_work"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Berkinerja Baik
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Berkinerja Baik
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={
                  "3. Pengalaman Dalam Pembiayaan Lingkungan Hidup / Kehutanan"
                }
                controllerName={"is_two_years_experience"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Berpengalaman Lebih dari 2 Tahun
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Berpengalaman Kurang dari 2 Tahun
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              <InputFormRadio
                label="&nbsp;"
                controllerName={"is_have_portfolio"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Memiliki Portofolio
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Memiliki Portofolio
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"4. Akses Debitur"}
                controllerName={"is_debtor_access"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Mempunyai Akses Langsung
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Mempunyai Akses Langsung
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"5. Sistem IT"}
                controllerName={"is_it_system"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Dapat Diintegrasikan
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Dapat Diintegrasikan
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"6. Jaminan"}
                controllerName={"is_collateral"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Jaminan Lebih dari 100% dari Nilai FDB Disetujui
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Jaminan di bawah 100% dari Nilai FDB Disetujui
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <InputForm
              controllerName={"sampling_result"}
              textArea={true}
              label={"Hasil Sampling Wawancara Debitur"}
              className={"w-full rounded-md border p-3"}
            />
          </CardForm>
          <CardForm label="Tindak Lanjut">
            <div className="flex gap-4">
              <InputFormRadio
                label={"Tindak Lanjut"}
                controllerName={"is_continue"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Dapat Dilanjutkan ke Tahap Risalah
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Dapat Dilanjutkan ke Tahap Risalah
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
          </CardForm>
        </div>
        <BottomFormAction
          backButtonAction={handleBack}
          submitActionButton={() => methods.handleSubmit(handleSubmit)()}
          disableDrafButton={false}
          hideSubmit={Number(searchParams.get("status")) > 1}
          hideDraft
          submitButtonProps={{
            type: "submit",
            label: "Submit",
          }}
          drafButtonAction={handleDraftButton}
          disableButtonSubmit={false}
          // hideDraft={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </FormProvider>
    </TabsContent>
  );
};

export default FieldResult;
