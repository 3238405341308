import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const postCommitteeMinutes = ({ data, params }) => {
  return axiosInstance.post(
    `${general_config_v2.BASE_URL}/v1/cms/committee-minutes`,
    data,
    { params }
  );
};

export const getCommitteeMinutes = (params) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/committee-minutes`,
    {
      params,
    }
  );
};

export const getCommitteeMinuteDetail = (id, params) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/committee-minutes/${id}`,
    {
      params,
    }
  );
};

export const verifiedCommitteeMinute = (id, params) => {
  return axiosInstance.post(
    `${general_config_v2.BASE_URL}/v1/cms/committee-minutes/${id}`,
    params
  );
};

export const updateRecommendation = (id, params) => {
  return axiosInstance.post(
    `${general_config_v2.BASE_URL}/v1/cms/committee-minutes/${id}`,
    params
  );
};

export const getMasterProvisions = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/provisions`, {
    params,
  });
};

// ? Committee Meeting Agenda
export const getCommitteeMeetingAgenda = (params) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/committee-meeting-agenda`,
    {
      params,
    }
  );
};

export const getCommitteeMeetingAgendaDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/committee-meeting-agenda/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const createCommitteeMeetingAgenda = (data) => {
  return axiosInstance.post(
    `${general_config_v2.BASE_URL}/v1/cms/committee-meeting-agenda`,
    data
  );
};

export const getListRisalahByMeetingAgendaId = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/committee-meeting-agenda/risalah/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

// ? UTILS
export const getUserByDivisiHierarchy = (divisi, hierarchy) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL_USER_MANAGEMENT}/v1/Employee/get-user-by-divisi-hierarchy`,
    {
      params: {
        idDivisi: divisi,
        idHierarcy: hierarchy,
      },
    }
  );
};

// ? Committee Pendapat Komite
export const getListPendapatKomite = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/expressed-opinion`,
    {
      params: queryKey[1],
    }
  );
};

export const getDetailPendapatKomite = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/expressed-opinion/${queryKey[1]}`,
    {
      params: queryKey[2],
    }
  );
};

export const updateAttendancePendapatKomite = (id, data, params) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/expressed-opinion/${id}`,
    data,
    { params }
  );
};

export const updateBulkRequestMemberRisalah = (id, data) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/expressed-opinion/bulk-request-member/${id}`,
    data
  );
};

export const updateUnitRequestMemberRisalah = (id, params) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/expressed-opinion/request-member/${id}`,
    {},
    { params }
  );
};

export const updateOfferApplicationApproval = ({ id, payload, params }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/offer-application-approval/${id}`,
    payload,
    { params }
  );
};
