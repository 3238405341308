import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, InputForm } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { Pagination } from "components/v2/shared/pagination";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  RiArrowLeftLine,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiEyeLine,
  RiFile3Line,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailPendapatKomite,
  updateAttendancePendapatKomite,
} from "services/fdb/committee";
import { postExpressedOpinionAttend } from "services/institutions/expressed-opinion";
import { getDetailMeetingAgenda } from "services/institutions/meeting-agenda";
import * as yup from "yup";
import { RisalahModalInstitution } from "../../MeetingAgenda/Components/modal-risalah-institution";
import { RisalahModal } from "../../MeetingAgenda/Components/modal-risalah";

const attendanceSchema = yup.object({
  is_attend: yup.bool().required(),
  reject_reason: yup.string().when("is_attend", ([value], schema) => {
    if (value === false) {
      return schema.required().label("Alasan");
    }
    return schema;
  }),
});

const PendapatKomiteDetail = ({ fdbType }) => {
  const params = useParams();
  const navigate = useNavigate();

  const [risalah, setRisalah] = useState();

  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
  });

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Agenda Rapat",
        path: "/committee/pendapat-komite",
      },
      {
        label: "Detail Agenda Rapat",
        path: "",
      },
    ],
    []
  );

  const methods = useForm({
    resolver: yupResolver(attendanceSchema),
    defaultValues: { is_attend: true, reject_reason: undefined },
  });

  const { data } = useQuery(
    ["GET_DETAIL_PENDAPAT_KOMITE", params.id, filters],
    getDetailPendapatKomite,
    {
      enabled: !!params.id && fdbType === "perorangan",
      select: (data) => data?.data?.data,
    }
  );
  const mutateUpdateAttendance = useMutation({
    mutationKey: ["UPDATE_ATTEND"],
    mutationFn: ({ payload, parameters }) =>
      updateAttendancePendapatKomite(params.id, payload, parameters),
  });

  const { data: dataInstitution } = useQuery(
    ["GET_DETAIL_PENDAPAT_KOMITE_LEMBAGA", params.id, filters],
    getDetailMeetingAgenda,
    {
      enabled: !!params.id && fdbType === "lembaga",
      select: (data) => data?.data?.data,
    }
  );
  const mutateUpdateAttendanceInstitution = useMutation({
    mutationKey: ["UPDATE_ATTEND_INSTITUTION"],
    mutationFn: postExpressedOpinionAttend,
  });

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      {
        key: "number",
        title: "No Risalah",
      },
      {
        key: "group_name",
        title: "Nama Kelompok",
      },
      {
        key: "total_member",
        title: "Jumlah Debitur",
        render: ({ item }) => (
          <p>
            {item?.total_member !== null ? `${item?.total_member} Orang` : "-"}
          </p>
        ),
      },
      {
        key: "member_rejected",
        title: "Debitur Ditolak",
        render: ({ item }) => (
          <p>
            {item?.member_rejected !== null
              ? `${item?.member_rejected} Orang`
              : "-"}
          </p>
        ),
      },
      {
        key: "member_approved",
        title: "Debitur Diterima",
        render: ({ item }) => (
          <p>
            {item?.member_approved !== null
              ? `${item?.member_approved} Orang`
              : "-"}
          </p>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            label={
              <RiEyeLine
                size={20}
                onClick={() => navigate(`risalah/${item.id}`)}
              />
            }
          />
        ),
      },
    ],
    [navigate]
  );

  const institutionHeaders = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      {
        key: "name",
        title: "Nama",
      },
      {
        key: "position",
        title: "Jabatan",
      },
      {
        key: "committee_position",
        title: "Kedudukan Dalam Komite",
      },
      {
        key: "is_attend",
        title: "Status Kehadiran",
      },
      {
        key: "is_approved",
        title: "Persetujuan",
      },
      {
        key: "approval_notes",
        title: "Notes",
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            label={
              <RiEyeLine
                size={20}
                onClick={() => navigate(`risalah/${item.id}`)}
              />
            }
          />
        ),
      },
    ],
    [navigate]
  );

  const handleAccept = (data) => {
    if (fdbType === "perorangan") {
      mutateUpdateAttendance.mutate(
        {
          payload: {
            reject_reason: "",
          },
          parameters: {
            is_attend: data?.is_attend,
          },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: "Kehadiran berhasil di submit",
            });
            navigate(-1);
          },
          onError: (err) => {
            enqueueSnackbar({
              variant: "error",
              message: "Kehadiran gagal di submit",
            });
          },
        }
      );

      return;
    }

    mutateUpdateAttendanceInstitution.mutate(
      {
        id: params.id,
        data: {
          reject_reason: "",
        },
        params: {
          is_attend: true,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Kehadiran berhasil di submit",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Kehadiran gagal di submit",
          });
        },
      }
    );
  };

  const handleReject = (data) => {
    if (fdbType === "perorangan") {
      mutateUpdateAttendance.mutate(
        {
          payload: {
            reject_reason: data?.reject_reason,
          },
          parameters: {
            is_attend: data?.is_attend,
          },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: "Kehadiran berhasil di submit",
            });
            navigate(-1);
          },
          onError: (err) => {
            enqueueSnackbar({
              variant: "error",
              message: "Kehadiran gagal di submit",
            });
          },
        }
      );

      return;
    }

    mutateUpdateAttendanceInstitution.mutate(
      {
        id: params.id,
        data: {
          reject_reason: data?.reject_reason,
        },
        params: {
          is_attend: false,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Kehadiran berhasil di submit",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Kehadiran gagal di submit",
          });
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-6">
        <BreadCrumbs routes={breadcrumbs} />

        <CardForm label="Detail">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-5">
              <h3 className="text-lg font-semibold">Pembahasan</h3>
              <div className="grid grid-cols-2 lg:grid-cols-2 gap-5">
                <div className="flex flex-col gap-2">
                  <h5 className="text-sm font-semibold">Topik Pembahasan</h5>
                  <p>
                    {data?.meeting?.topic_discussion ??
                      dataInstitution?.detail?.topic_discussion}
                  </p>
                </div>
                <Modal
                  trigger={
                    <div
                      className="text-sm font-medium flex items-center gap-3 group cursor-pointer shadow rounded-md p-2 w-max"
                      onClick={() => {
                        if (fdbType === "lembaga") {
                          setRisalah(dataInstitution?.detail?.risalah);
                        }
                      }}
                    >
                      <div className="p-[10px] bg-primary-100 rounded-full text-primary-700">
                        <RiFile3Line size={20} />
                      </div>
                      <p className="group-hover:underline">
                        Lihat Daftar Risalah
                      </p>
                    </div>
                  }
                  contentClassName="!w-[1184px] !max-w-[1184px] !z-[51]"
                >
                  {fdbType === "lembaga" ? (
                    <RisalahModalInstitution risalah={risalah} />
                  ) : (
                    <RisalahModal meetingId={params.id} />
                  )}
                </Modal>
              </div>
            </div>

            <div className="flex flex-col gap-5">
              <h3 className="text-lg font-semibold">Tanggal pelakasanaan</h3>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="flex flex-col gap-2">
                  <h5 className="text-sm font-semibold">Jadwal Rapat</h5>
                  <p>
                    {data?.meeting?.implementation_time
                      ? dayjs(data?.meeting?.implementation_time).subtract(7, 'hour').format(
                        "dddd, DD MMMM YYYY ; HH:mm A"
                      )
                      : dataInstitution?.detail?.implementation_time
                        ? dayjs(
                          dataInstitution?.detail?.implementation_time
                        ).subtract(7, 'hour').format("dddd, DD MMMM YYYY ; HH:mm A")
                        : "-"}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-sm font-semibold">Tempat</h5>
                  <p>
                    {data?.meeting?.implementation_place ??
                      dataInstitution?.detail?.implementation_place}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <h5 className="text-sm font-semibold">Catatan</h5>
                  <p>
                    {data?.meeting?.notes ?? dataInstitution?.detail?.notes}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CardForm>

        {fdbType === "perorangan" && (
          <TableWrapper>
            <Table headers={headers} items={data?.risalah?.data ?? []} />
            <Pagination
              page={data?.risalah?.meta?.current_page ?? 1}
              limit={filters.limit}
              total={data?.risalah?.meta?.total ?? 0}
              totalPage={data?.risalah?.meta?.last_page ?? 1}
              onLimitChange={(e) =>
                setFilters((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
            />
          </TableWrapper>
        )}

        {fdbType === "lembaga" && (
          <TableWrapper>
            <Table
              headers={institutionHeaders}
              items={dataInstitution?.directorates ?? []}
            />
            <Pagination
              page={data?.risalah?.meta?.current_page ?? 1}
              limit={filters.limit}
              total={data?.risalah?.meta?.total ?? 0}
              totalPage={data?.risalah?.meta?.last_page ?? 1}
              onLimitChange={(e) =>
                setFilters((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
            />
          </TableWrapper>
        )}

        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
          <Button
            className="p-4 border rounded-lg"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                <RiArrowLeftLine />
                Kembali
              </div>
            }
            onClick={() => navigate(-1)}
          />
          <div className="flex gap-3">
            <Modal
              trigger={
                <Button
                  className="p-4 bg-error-700 hover:bg-error-600 text-white"
                  type="button"
                  label={
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <RiCloseCircleLine />
                      Tolak Undangan
                    </div>
                  }
                  onClick={() => methods.setValue("is_attend", false)}
                />
              }
              contentClassName="!w-[686px] !max-w-[686px] !z-[51]"
            >
              <ModalHeader>Tolak</ModalHeader>
              <ModalBody className="space-y-5">
                <InputForm
                  className="w-full p-3 border"
                  controllerName="reject_reason"
                  label="Alasan Penolakan"
                  required
                  textArea
                />

                <hr />
                <div className="flex gap-3">
                  <ModalClose>
                    <Button
                      label="Batal"
                      className="flex flex-grow border justify-center font-semibold text-sm"
                    />
                  </ModalClose>
                  <Button
                    label="Submit"
                    className="flex flex-grow bg-primary-700 hover:bg-primary-600 text-white justify-center font-semibold text-sm"
                    onClick={methods.handleSubmit(handleReject)}
                  />
                </div>
              </ModalBody>
            </Modal>

            <Button
              className="p-4 border rounded-lg bg-primary-700 text-white hover:bg-primary-600"
              type="button"
              label={
                <div className="flex items-center gap-2 text-sm font-medium">
                  <RiCheckboxCircleLine />
                  Menghandiri Undangan
                </div>
              }
              onClick={() => {
                methods.setValue("is_attend", true);
                methods.handleSubmit(handleAccept)();
              }}
            />
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default PendapatKomiteDetail;
